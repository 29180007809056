class FreeSky
{
    constructor(options)
    {
        this.loadjQuery();

        setTimeout(() => {
            if(this.$)
            {
                if(options.privacy != true)
                {
                    this.$.ajax({
                        method: 'post',
                        url: 'http://freesky.in/widget/v1/collect',
                        data: JSON.stringify(this.options),
                        contentType: 'application/json',
                        dataType: 'json',
                    });
                }
            }
        }, 500);

        this.options = options;

        for(let key in options)
            this[key] = options[key];

    }

    begin()
    {
        this.getObjName();
        setTimeout(() => {
            if(this.$)
                this.validate();
        }, 500);
    }

    validate()
    {
        // Set errors to empty array
        let errors = {};
        this.errorCodes = [];
        errors.count = 0;

        // Check Key
        if(this.key == undefined)
        {
            errors.key = {
                'code' : 101,
                'message' : 'Api Authentication Key is required.'
            };
            this.errorCodes.push(101);
            errors.count++;
        }
        else if(this.key.length != 20)
        {
            errors.key = {
                code: 102,
                message: 'Api Authentication Key is Invalid.'
            };
            this.errorCodes.push(102);
            errors.count++;
        }

        // Check Email
        if(this.email != undefined)
            if(!/^.+@.+\..+$/.test(this.email))
            {
                errors.email = {
                    code: 201,
                    message: 'Email is Invalid'
                };
                this.errorCodes.push(201);
                errors.count++;
            }

        // Check card number
        if(this.card != undefined)
        {
            if(!/^\d{16}$/.test(this.card))
            {
                errors.card = {
                    code: 301,
                    message: 'Card Number is Invalid'
                };
                this.errorCodes.push(301);
                errors.count++;
            }
        }

        // Check prodcut_info length
        if(this.product_info == undefined)
        {
            errors.product_info = {
                code: 401,
                message: 'Product Information is mandatory.'
            };
            this.errorCodes.push(401);
            errors.count++;
        }
        else if(this.product_info.length == 0)
        {
            errors.product_info = {
                code: 402,
                message: 'At least one Product Information is required.'
            };
            this.errorCodes.push(402);
            errors.count++;
        }
        else // Check if all product_info has name, amount int in paise & category properties
        {
            errors.product_info = {};
            this.product_info.forEach((item, index) => {

                if(item.name == '' || item.name == undefined)
                {
                    errors.product_info[index] = { 'name' : {
                        code: 411,
                        message: 'Product Name is required and cannot be empty'
                    }};

                    if(this.$.inArray(411, this.errorCodes) == -1)
                        this.errorCodes.push(411);

                    errors.count++;
                }

                if(typeof item.amount != 'number')
                {
                    errors.product_info[index] = { 'amount' : {
                        code: 421,
                        message: 'Amount is required in integer'
                    }};

                    if(this.$.inArray(421, this.errorCodes) == -1)
                        this.errorCodes.push(421);

                    errors.count++;
                }
                else if(item.amount <= 0)
                {
                    errors.product_info[index] = { 'amount' : {
                        code: 422,
                        message: 'Amount cannot be less than 1'
                    }};

                    if(this.$.inArray(422, this.errorCodes) == -1)
                        this.errorCodes.push(422);

                    errors.count++;
                }

                if(typeof item.quantity != 'number')
                {
                    errors.product_info[index] = { 'quantity' : {
                        code: 431,
                        message: 'Quantity is required in integer'
                    }};

                    if(this.$.inArray(431, this.errorCodes) == -1)
                        this.errorCodes.push(431);

                    errors.count++;
                }
                else if(item.quantity <= 0)
                {
                    errors.product_info[index] = { 'quantity' : {
                        code: 432,
                        message: 'Quantity cannot be less than 1'
                    }};

                    if(this.$.inArray(432, this.errorCodes) == -1)
                        this.errorCodes.push(432);

                    errors.count++;
                }

                if(!/^[0-9a-zA-Z\-]+$/.test(item.category))
                {
                    errors.product_info[index] = { 'category' : {
                        code: 441,
                        message: 'Invalid Category slug syntax.'
                    }};

                    if(this.$.inArray(441, this.errorCodes) == -1)
                        this.errorCodes.push(441);

                    errors.count++;
                }

            });
        }

        // If there are no errors in product_info then delete the object
        if(this.$.isEmptyObject(errors.product_info))
            delete errors['product_info'];

        // Check for success callback function
        if(typeof this.success != 'function')
        {
            errors.success = {
                code: 501,
                message: 'A success callback is mandatory.'
            };
            this.errorCodes.push(501);
            errors.count++;
        }

        // Check for error callback function
        if(typeof this.error != 'function')
        {
            errors.error = {
                code: 601,
                message: 'An error callback is mandatory.'
            };
            this.errorCodes.push(601);
            errors.count++;
        }


        if(errors.count == 0)
            this.successView();
        else
        {
            delete errors['count'];
            this.error(errors);
            this.errorView();
        }

        return this;
    }

    apply()
    {
        this.options.card = this.$("#freesky-container #card").val().split("-").join("");
        this.options.mobile = this.$("#freesky-container #mobile").val();

        let errors = false;

        if(!/^(9|8|7)[0-9]{9}$/.test(this.$('#freesky-container #mobile').val()))
        {
            errors = true;
            this.$('#freesky-container #mobile-error').html('It seems like you have entered wrong mobile number. Please enter correct mobile number.');
        }
        else
            this.$('#freesky-container #mobile-error').html('&nbsp;');

        if(!/^[0-9]{16}$/.test(this.$('#freesky-container #card').val().split("-").join("")))
        {
            errors = true;
            this.$('#freesky-container #card-error').html('Oppsss...! This card number is not valid. Please recheck card number.');
        }
        else
            this.$('#freesky-container #card-error').html('&nbsp;');

        if(!errors)
        {
            this.$('#freesky-loader').attr('style', 'display: block !important');
            this.$.ajax({
                method: 'post',
                url: 'http://freesky.in/widget/v1/apply',
                data: JSON.stringify(this.options),
                contentType: 'application/json',
                dataType: 'json',
                success: (response) => {
                    this.$('#freesky-loader').attr('style', 'display: none !important');
                    if(response.error)
                    {
                        if(response.error.card)
                            this.$('#freesky-container #card-error').html(response.error.card[0]);
                        else
                        {
                            for(let item in response.error)
                            {
                                if(response.error[item].code)
                                    this.errorCodes.push(response.error[item].code);
                                else
                                {
                                    for(let item2 in response.error[item])
                                    {
                                        for(let item3 in response.error[item][item2])
                                        {
                                            if(response.error[item][item2][item3].code)
                                                this.errorCodes.push(response.error[item][item2][item3].code);
                                        }
                                    }
                                }
                            };

                            this.error(response.error);
                            this.errorView();
                        }
                    }
                    else if(response.success)
                    {
                        this.$('.freesky-content').addClass('freesky-verified').html(`
                            <div class="freesky-header">
                                <div class="freesky-logo">
                                    <img src="http://freesky.in/widget/v1/images/logo2.png" alt="" />
                                </div>
                                <span id="freesky-close" onclick="${ this.objName }.destroy()">x</span>
                            </div>
                            <div class="freesky-partner-name">
                                <h3>${this.title ? this.title : window.location.hostname}</h3>
                            </div>
                            <div class="freesky-content-box">
                            <center>
                                <h3>
                                <img src="http://freesky.in/widget/v1/images/fa-check-circle.png">
                                </h3>
                            </center>
                                <h3>Successfully Applied</h3><br>
                                <table>
                                    <tr><td>Bill Amount</td><td class="freesky-rs">${ ( response.success.amount / 100 ).toString().replace(/(\d)(?=(\d\d)+\d$)/g, "$1,") }</td></tr>
                                    <tr><td>FreeSky Discount</td><td class="freesky-rs">- ${ ( response.success.discount / 100 ).toString().replace(/(\d)(?=(\d\d)+\d$)/g, "$1,") }</td></tr>
                                    <tr><td colspan="3"></td></tr>
                                    <tr><td>Total</td><td class="freesky-rs">${ ( ( response.success.total ) / 100 ).toString().replace(/(\d)(?=(\d\d)+\d$)/g, "$1,") }</td></tr>
                                </table>
                                <h3 class="freesky-thanks-span">Thank You for Using Freesky Discount Card</h3>
                            </div>
                            <a href="javascript:void(0);" class="freesky-paynow" onclick="${this.objName}.destroy()">Continue <img src="http://freesky.in/widget/v1/images/fa-arrow-right.png" style="vertical-align: middle !important; height: 25px !important;"></a>
                        `);
                        this.reference = response.success.reference;
                    }
                },
                error: (response) => {
                    this.$('#freesky-loader').attr('style', 'display: none !important');
                    this.error(response.responseJSON);
                    this.errorView();
                }
            });
        }

    }

    getObjName()
    {
        for (var name in window)
            if(window[name] instanceof FreeSky)
                this.objName = name;

        return this;
    }

    loadjQuery()
    {
        /******** Load jQuery if not present *********/
        if (window.jQuery === undefined || window.jQuery.fn.jquery !== '2.2.4') {
            let script_tag = document.createElement('script');
            script_tag.setAttribute("type", "text/javascript");
            script_tag.setAttribute("src", "https://cdnjs.cloudflare.com/ajax/libs/jquery/2.2.4/jquery.min.js");
            if (script_tag.readyState) {
                script_tag.onreadystatechange = function () { // For old versions of IE
                    if (this.readyState == 'complete' || this.readyState == 'loaded') {
                        this.$ = window.jQuery.noConflict(true);
                        // this.validate();
                    }
                };
            } else {
                script_tag.onload = () => {
                    this.$ = window.jQuery.noConflict(true);
                    // this.validate();
                };
            }
            // Try to find the head, otherwise default to the documentElement
            (document.getElementsByTagName("head")[0] || document.documentElement).appendChild(script_tag);
        } else {
            // The jQuery version on the window is the one we want to use
            this.$ = window.jQuery;
            // this.validate();
        }

        return this;
    }

    successView()
    {
        this.$('head').append(`
            <style id="freesky-style">
                @import 'http://freesky.in/widget/v1/css/cleanslate.css';
                @import 'https://fonts.googleapis.com/css?family=Muli';
                #freesky-container{
                    z-index: 9999 !important;
                    top: 0 !important;
                    left: 0 !important;
                    height: 100% !important;
                    width: 100% !important;
                    position: fixed !important;
                    margin: 0 !important;
                    font-family: 'Muli', sans-serif !important;
                    background: rgba(0, 0, 0, 0.6) !important;
                }
                #freesky-container .freesky-content{
                    max-width: 400px !important;
                    margin: auto !important;
                    background: white !important;
                    border-radius: 5px !important;
                    overflow: hidden !important;
                    margin-top: 4em !important;
                    box-shadow: 5px 5px 10px 0px #3e3e3e !important;
                    position: relative !important;
                }
                #freesky-container .freesky-content .freesky-header, #freesky-container a.freesky-paynow{
                    background: rgba(73,155,234,1) !important;
                    background: -moz-linear-gradient(-45deg, rgba(73,155,234,1) 0%, rgba(32,124,229,1) 100%) !important;
                    background: -webkit-gradient(left top, right bottom, color-stop(0%, rgba(73,155,234,1)), color-stop(100%, rgba(32,124,229,1))) !important;
                    background: -webkit-linear-gradient(-45deg, rgba(73,155,234,1) 0%, rgba(32,124,229,1) 100%) !important;
                    background: -o-linear-gradient(-45deg, rgba(73,155,234,1) 0%, rgba(32,124,229,1) 100%) !important;
                    background: -ms-linear-gradient(-45deg, rgba(73,155,234,1) 0%, rgba(32,124,229,1) 100%) !important;
                    background: linear-gradient(135deg, rgba(73,155,234,1) 0%, rgba(32,124,229,1) 100%) !important;
                    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#499bea', endColorstr='#207ce5', GradientType=1 ) !important;
                }
                #freesky-container .freesky-header{
                    padding: 8px 0 !important;
                    width: 100% !important;
                    position: relative !important;
                }
                #freesky-container .freesky-logo{text-align: center !important;display: block !important;}
                #freesky-container .freesky-content .freesky-content-box{
                    color: black !important;
                    padding: 0 1em !important;
                }
                #freesky-container .freesky-partner-name{color: #2882e6 !important;text-align: center !important;}
                #freesky-container .freesky-partner-name h3{font-size: 18px !important;margin: 0 !important;margin-top: 15px !important;}
                #freesky-container .freesky-partner-name p{font-size: 15px !important;margin: 5px 0 !important;}
                #freesky-container .freesky-content .freesky-content-box input[type="text"] {
                    width: 96% !important;
                    border: 0 !important;
                    border-bottom: 1px solid #ececec !important;
                    padding: 0 7px !important;
                    padding-bottom: 5px !important;
                    margin: 15px 0 0 0 !important;
                    font-size: 15px !important;
                    font-family: Muli !important;
                }
                #freesky-container .freesky-content .freesky-content-box input[type="text"]:focus {
                    border-bottom:1px solid #4ba0ff !important;
                    outline: 0 !important;
                }
                #freesky-container .freesky-content .freesky-content-box input[type="text"]::-webkit-input-placeholder {
                    color: gray !important;
                }

                #freesky-container .freesky-content .freesky-content-box input[type="text"]:-moz-placeholder { /* Firefox 18- */
                    color: gray !important;
                }

                #freesky-container .freesky-content .freesky-content-box input[type="text"]::-moz-placeholder {  /* Firefox 19+ */
                    color: gray !important;
                }

                #freesky-container .freesky-content .freesky-content-box input[type="text"]:-ms-input-placeholder {
                    color: gray !important;
                }
                #freesky-container .freesky-form-group-inline{
                    margin-bottom: 20px !important;
                    position: relative !important;
                }
                #freesky-container .freesky-form-group-inline .freesky-phone-icon,
                #freesky-container .freesky-form-group-inline .freesky-card-icon {
                    font-size: 18px !important;
                    position: absolute !important;
                    top: 7px !important;
                    right: 5px !important;
                    color: silver !important;
                    font-weight: 100 !important;
                    height: 20px !important;
                    width: 20px !important;
                }
                #freesky-container .freesky-content .freesky-content-box h3{
                    margin-bottom: 0 !important;
                    color: #03bdd5 !important;
                }
                #freesky-container .freesky-content .freesky-content-box p{
                    padding-left:1em !important;
                    color: gray !important;
                }
                #freesky-container a.freesky-paynow{
                    width: 100% !important;
                    display: block !important;
                    padding: 10px 0 !important;
                    color: white !important;
                    text-decoration: none !important;
                    text-align: center !important;
                    font-size: 30px !important;
                }
                #freesky-container .freesky-content-box p.freesky-label {
                    padding: 0 !important;
                    font-size: 15px !important;
                    margin-bottom: -7px !important;
                }
                #freesky-container .freesky-content.freesky-verified .freesky-partner-name h3{
                    margin-top: 15px !important;
                }
                #freesky-container .freesky-content.freesky-verified .freesky-content-box {
                    margin-top: -5px !important;
                }
                #freesky-container .freesky-content.freesky-verified .freesky-content-box h3 {
                    color: #65bb00 !important;
                    margin: 0 !important;
                    text-align: center !important;
                }
                #freesky-container .freesky-content.freesky-verified .freesky-content-box table {
                    width: 100% !important;
                }
                #freesky-container .freesky-content.freesky-verified .freesky-content-box table tr+tr+tr > td{
                    border-top: 1px solid #dedede !important;
                }
                #freesky-container .freesky-content.freesky-verified .freesky-content-box table tr+tr+tr+tr td{
                border: 0 !important;
                }
                @media(max-width:500px){
                    #freesky-container .freesky-content{
                        margin: 35% .5em !important;
                    }
                }
                #freesky-container .freesky-rs:after {
                    content: " ₹" !important;
                    font-size: 15px !important;
                    color: #a2a2a2 !important;
                }
                #freesky-container table td {
                    color: gray !important;
                    padding: 3px !important;
                }
                #freesky-container table td.freesky-rs {
                    color: black !important;
                    text-align: right !important;
                }
                #freesky-container .freesky-input-error {
                    margin-top: 5px !important;
                    display: block !important;
                    font-size: 15px !important;
                    color: #F44336 !important;
                }
                #freesky-container .freesky-thanks-span {
                    padding: 15px 0 !important;
                }
                #freesky-container #freesky-loader {
                    position: absolute !important;
                    top: 0 !important;
                    width: 100% !important;
                    height: 100% !important;
                    background: rgba(255, 255, 255, 0.67) !important;
                    display: none !important;
                }
                #freesky-container #freesky-loader-image {
                    margin: auto !important;
                    top: 50% !important;
                    display: block !important;
                    position: relative !important;
                    margin-top: -50px !important;
                }
                #freesky-container #freesky-close {
                    position: absolute !important;
                    top: 5px !important;
                    right: 10px !important;
                    color: #fff !important;
                    font-size: 25px !important;
                    cursor: pointer !important;
                    padding: 0 2px !important;
                }
            </style>
        `);

        this.$('body').css('overflow', 'hidden');

        if(this.$('#freesky-container').length)
            this.$('#freesky-container').remove();

        this.$('body').append('<div id="freesky-container" class="cleanslate" style="display:none !important;"></div>');

        this.$('#freesky-container').html(`
            <div class="freesky-content">
                <div>
                    <div class="freesky-header">
                        <div class="freesky-logo">
                            <img src="http://freesky.in/widget/v1/images/logo2.png" alt="" />
                        </div>
                        <span id="freesky-close" onclick="${ this.objName }.destroy()">x</span>
                    </div>
                     <div class="freesky-partner-name">
                            <h3>${this.title ? this.title : window.location.hostname}</h3>
                        </div>
                    <div class="freesky-content-box error">
                        <p class="freesky-label">Mobile Number (Registered on Freesky)</p>
                        <div class="freesky-form-group-inline">
                            <input type="text" id="mobile" value="">
                            <img src="http://freesky.in/widget/v1/images/fa-phone.png" class="freesky-phone-icon">
                            <span id="mobile-error" class="freesky-input-error">&nbsp;</span>
                        </div>
                        <p class="freesky-label">Enter FreeSky Card Number</p>
                        <div class="freesky-form-group-inline">
                            <input type="text" id="card" value="${ this.card ? this.card.match(new RegExp('.{1,4}', 'g')).join('-') : '' }" maxlength="19">
                            <img src="http://freesky.in/widget/v1/images/fa-credit-card.png" class="freesky-card-icon">
                            <span id="card-error" class="freesky-input-error">&nbsp;</span>
                        </div>
                    </div>
                    <a href="javascript:void(0);" class="freesky-paynow" onclick="${this.objName}.apply()">Apply</a>
                </div>
                <div id="freesky-loader">
                    <img id="freesky-loader-image" src="http://freesky.in/widget/v1/images/loader.gif" >
                </div>
            </div>
            `);

        this.$('#freesky-container').fadeIn(() => {
            this.$('#freesky-container').attr('style', 'display: block !important');
        });

        this.$("#freesky-container #card").keyup(function() {
            let card = this.value.split("-").join("");
            if (card.length > 0) {
                card = card.match(new RegExp(".{1,4}", "g")).join("-");
            }
            this.value = card;
        });

        return this;
    }

    errorView()
    {
        this.$('head').append(`
            <style id="freesky-style">
                @import 'https://fonts.googleapis.com/css?family=Muli';
                #freesky-container{
                    z-index: 9999 !important;
                    top: 0 !important;
                    left: 0 !important;
                    height: 100% !important;
                    width: 100% !important;
                    position: fixed !important;
                    margin: 0 !important;
                    font-family: 'Muli', sans-serif !important;
                    background: rgba(0, 0, 0, 0.6) !important;
                }
                #freesky-container .freesky-content{
                    max-width: 400px !important;
                    margin: auto !important;
                    background: white !important;
                    border-radius: 5px !important;
                    overflow: hidden !important;
                    margin-top: 4em !important;
                    box-shadow: 5px 5px 10px 0px #3e3e3e !important;
                    position: relative !important;
                }
                @media(max-width:500px){
                    #freesky-container .freesky-content{
                        margin: 35% .5em !important;
                    }
                }
                #freesky-container .freesky-content .freesky-header, #freesky-container a.freesky-paynow{
                    background: rgba(73,155,234,1) !important;
                    background: -moz-linear-gradient(-45deg, rgba(73,155,234,1) 0%, rgba(32,124,229,1) 100%) !important;
                    background: -webkit-gradient(left top, right bottom, color-stop(0%, rgba(73,155,234,1)), color-stop(100%, rgba(32,124,229,1))) !important;
                    background: -webkit-linear-gradient(-45deg, rgba(73,155,234,1) 0%, rgba(32,124,229,1) 100%) !important;
                    background: -o-linear-gradient(-45deg, rgba(73,155,234,1) 0%, rgba(32,124,229,1) 100%) !important;
                    background: -ms-linear-gradient(-45deg, rgba(73,155,234,1) 0%, rgba(32,124,229,1) 100%) !important;
                    background: linear-gradient(135deg, rgba(73,155,234,1) 0%, rgba(32,124,229,1) 100%) !important;
                    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#499bea', endColorstr='#207ce5', GradientType=1 ) !important;
                }
                #freesky-container .freesky-header{
                    padding: 8px 0 !important;
                    width: 100% !important;
                    position: relative !important;
                }
                #freesky-container .freesky-logo{text-align: center !important;display: block !important;}
                #freesky-container .freesky-content .freesky-content-box{
                    color: black !important;
                    padding: 0 1em !important;
                }
                #freesky-container a.freesky-paynow{
                    width: 100% !important;
                    display: block !important;
                    padding: 10px 0 !important;
                    color: white !important;
                    text-decoration: none !important;
                    text-align: center !important;
                    font-size: 30px !important;
                }
                #freesky-container #freesky-close {
                    position: absolute !important;
                    top: 5px !important;
                    right: 10px !important;
                    color: #fff !important;
                    font-size: 25px !important;
                    cursor: pointer !important;
                    padding: 0 2px !important;
                }
                #freesky-container #freesky-error{
                    color: #949494 !important;
                    padding: 2em 1em !important;
                }
                #freesky-container #freesky-error img{
                    margin: auto !important;
                    display: block !important;
                }
                #freesky-container #freesky-error h3{
                    font-weight: 100 !important;
                    text-shadow: 0px 1px 0px #fbfbfb !important;
                    margin-top: 20px !important;
                    text-align: center !important;
                }
                #freesky-container .freesky-paynow.freesky-close {
                    background: #FF5722 !important;
                }
            </style>
        `);

        this.$('body').css('overflow', 'hidden');

        if(this.$('#freesky-container').length)
            this.$('#freesky-container').remove();

        this.$('body').append('<div id="freesky-container" class="cleanslate"></div>');

        this.$('#freesky-container').html(`
            <div class="freesky-content">
                <div>
                    <div class="freesky-header">
                        <div class="freesky-logo">
                            <img src="http://freesky.in/widget/v1/images/logo2.png" alt="" />
                        </div>
                        <span id="freesky-close" onclick="${ this.objName }.destroy()">x</span>
                    </div>
                    <div id="freesky-error">
                        <img src="http://freesky.in/widget/v1/images/fa-exclamation-circle.png">
                        <h3>Oppp...!!! Freesky in not getting response from ${ this.title ? this.title : window.location.hostname }</h3>
                        <p>Error : ${ this.errorCodes.join(', ') }</p>
                    </div>
                    <a href="#" class="freesky-paynow freesky-close" onclick="${ this.objName }.destroy()">Close</a>
                </div>
            </div>
        `);
    }

    destroy()
    {
        if(this.reference)
        {
            this.success({ 'reference': this.reference });
            delete this.reference;
        }

        this.$('#freesky-container').fadeOut(() => {
            this.$('#freesky-container').remove();
            this.$('#freesky-style').remove();
            this.$('body').css('overflow', '');
        });
    }

}
